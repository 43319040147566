import type { CrossSelling } from "@shopware-pwa/types";

export const useShopwareProductCrossSell = (productId: string) => {
	const shopwareApi = useShopwareApi();

	const { pending, data, execute } = useLazyAsyncData(
		`product:associations:${productId}`,
		async () => {
			const { data } = await shopwareApi.invoke.post<CrossSelling[]>(
				`/store-api/product/${productId}/cross-selling`,
				{
					associations: {
						cover: {
							associations: {
								media: {}
							}
						}
					}
				}
			);

			return data;
		},
		{ immediate: false, default: () => [] as CrossSelling[] }
	);

	return {
		productAssociations: data,
		loadAssociations: execute,
		isLoading: pending
	};
};
