<script setup lang="ts">
import { getRoute } from "~/lib/routes";

const { closeDrawer } = defineProps<{
	closeDrawer: () => void;
}>();

const close = () => {
	closeDrawer();
};

const { onBeginCheckout } = useGtagEvents();

const goToCheckout = async () => {
	close();
	onBeginCheckout();
};
</script>

<template>
	<div class="flex flex-col gap-6">
		<div class="flex flex-col gap-4">
			<KippieLink :to="getRoute('checkoutLogin')" @click="goToCheckout" class="no-underline">
				<KippieButton block color="yellow" size="small" :fake="true"> Doorgaan naar bestellen </KippieButton>
			</KippieLink>
			<KippieButton color="white" @click="close" size="small">Verder winkelen</KippieButton>
		</div>
	</div>
</template>
