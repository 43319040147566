<script setup lang="ts">
import type { KippieProduct } from "@shopware-pwa/types";
import { getProduct } from "@shopware-pwa/api-client";
import DepositProductPrompt from "./DepositProductPrompt.vue";
import CrossSellPrompt from "./CrossSellPrompt.vue";
import ContinueShoppingPrompt from "./ContinueShoppingPrompt.vue";
import PickupLocationPrompt from "../shared/PickupLocationPrompt.vue";
import { generateCriteria, type BaseCrossSellChannel } from "~/helpers/productHelpers";
import { SHIPPING_METHODS, SHIPPING_METHODS_LIST } from "~/lib/constants";

const { product, productId: productIdProp } = defineProps<
	| {
			product: KippieProduct;
			productId?: string;
	  }
	| {
			product?: KippieProduct;
			productId: string;
	  }
>();

const isLoading = ref(false);
const shopwareApi = useShopwareApi();
const { addProduct, selectedPickupLocation } = useShopwareCart();

const { onAddToCart: gtagOnAddToCart } = useGtagEvents();
const { addToCart: pinterestAddToCart } = usePinterestTrackingEvents();
const { addedToCartObjectIDs } = useAlgoliaSearchInsights();
const snackbar = useSnackbar();
const { selectedShippingMethod, setShippingMethod } = useShopwareCheckout();

const productId = computed(() => {
	return (product?.id || productIdProp) as string;
});

const drawerProvider = useDrawer();

const currentShippingMethod = computed(() => {
	return SHIPPING_METHODS_LIST.find((method) => method.shopwareId === selectedShippingMethod.value?.shopwareId);
});

const {
	isLoading: isLoadingCrossSell,
	productAssociations,
	loadAssociations
} = useShopwareProductCrossSell(productId.value);

const { data: addToCartProduct, execute: loadProduct } = useCachedAsyncData(
	`shopware:product:${productId.value}`,
	async () => {
		if (product) return product as KippieProduct;

		const productResponse = await getProduct(
			productId.value,
			{
				...generateCriteria()
			},
			shopwareApi
		);

		return productResponse.product as KippieProduct;
	},
	{ immediate: false, lazy: true }
);

const promptDepositProduct = async (productId: string) => {
	await drawerProvider.showDrawer(DepositProductPrompt, {
		drawerTitle: "Elektrische pan erbij?",
		productId
	});
};

const promptCrossSell = async (crossSellChannel: BaseCrossSellChannel) => {
	await drawerProvider.showDrawer(CrossSellPrompt, {
		drawerTitle: crossSellChannel.name,
		isLoading: isLoadingCrossSell,
		productAssociations,
		crossSellChannel
	});
};

const promptContinueShopping = async () => {
	await drawerProvider.showDrawer(ContinueShoppingPrompt, {
		drawerTitle: "Het artikel is toegevoegd"
	});
};

const onAddToCart = async (e: Event, selectPickupLocation?: boolean) => {
	e.preventDefault();
	isLoading.value = true;

	await loadProduct();

	if (!addToCartProduct.value) {
		return snackbar.open({
			content: "Er is iets misgegaan, probeer het later opnieuw.",
			icon: "info",
			iconColor: "red"
		});
	}

	if (selectPickupLocation && currentShippingMethod.value?.type !== "pickup") {
		setShippingMethod(SHIPPING_METHODS.pickup.shopwareId);
	}

	addProduct(addToCartProduct.value);
	pinterestAddToCart(addToCartProduct.value);

	gtagOnAddToCart(addToCartProduct.value, 1);
	addedToCartObjectIDs([addToCartProduct.value.id], [addToCartProduct.value]);

	if (!!addToCartProduct.value.crossSellings && addToCartProduct.value.crossSellings?.length > 0) {
		loadAssociations();
	}

	if (addToCartProduct.value.customFields.kippie_deposit_fields_deposit_product) {
		await promptDepositProduct(addToCartProduct.value.customFields.kippie_deposit_fields_deposit_product);
	}

	if (!!addToCartProduct.value.crossSellings && addToCartProduct.value.crossSellings.length > 0) {
		await promptCrossSell(addToCartProduct.value.crossSellings[0] as unknown as BaseCrossSellChannel);
	}

	if (selectPickupLocation && !selectedPickupLocation.value) {
		await drawerProvider.showDrawer(PickupLocationPrompt, {
			drawerTitle: "Kies een afhaallocatie"
		});
	}

	promptContinueShopping();

	snackbar.open({
		content: "Product toegevoegd aan winkelmandje",
		icon: "shopping-bag",
		iconColor: "white"
	});

	isLoading.value = false;
};
</script>

<template>
	<slot :on-add-to-cart="onAddToCart" :is-loading="isLoading" />
</template>
