<script setup lang="ts">
import { getProduct } from "@shopware-pwa/api-client";

import { formatPrice } from "~/helpers/formatters";

const { productId, closeDrawer } = defineProps<{
	productId: string;
	closeDrawer: () => void;
}>();

const { addProduct } = useShopwareCart();
const apiInstance = useShopwareApi();

const cacheKey = `shopware:product:${productId}`;
const { data: depositProduct } = useCachedAsyncData(
	cacheKey,
	async () => (await getProduct(productId, {}, apiInstance)).product
);

const onAddProduct = () => {
	addProduct({ id: productId, quantity: 1 });

	closeDrawer();
};

defineExpose({
	onDrawerClose: () => {
		addProduct({ id: productId, quantity: 1 });
	}
});

const close = () => {
	closeDrawer();
};
</script>

<template>
	<div class="flex flex-col gap-6">
		<div class="flex flex-col gap-4">
			<KippieButton @click="onAddProduct" size="small">
				Met pan
				{{ !!depositProduct ? `(${formatPrice(depositProduct.calculatedPrice.totalPrice || 0, true)})` : "" }}
			</KippieButton>
			<KippieButton color="white" @click="close" size="small">Alleen de panvulling</KippieButton>
		</div>

		<span class="text-xs text-gray">
			Voor de Kippiepan betaal je
			{{ !!depositProduct ? formatPrice(depositProduct.calculatedPrice.totalPrice || 0, true) : "" }} borg. Deze
			borg krijg je terug als je de pan (schoon) inlevert bij de winkel. Er zit geen uiterlijke termijn aan het
			inleveren van de pan, houd deze gerust thuis om een volgende keer enkel de inhoud te bestellen!
		</span>
	</div>
</template>
