<script setup lang="ts">
import { getTranslatedProperty } from "@shopware-pwa/helpers-next";
import type { CrossSelling } from "@shopware-pwa/types";
import type { KippieProduct } from "@shopware-pwa/types";
import { formatPrice } from "~/helpers/formatters";
import { getThumbnailUrl } from "~/helpers/mediaHelpers";
import type { BaseCrossSellChannel } from "~/helpers/productHelpers";

const { isLoading, productAssociations, closeDrawer } = defineProps<{
	isLoading: Ref<boolean>;
	productAssociations: Ref<CrossSelling[]>;
	crossSellChannel: BaseCrossSellChannel;
	closeDrawer: () => void;
}>();

const { addProduct } = useShopwareCart();
const { addedToCartObjectIDs } = useAlgoliaSearchInsights();

const selectedProducts = ref<string[]>([]);
const isUpdatingCart = ref(false);

const products = computed(() => {
	return productAssociations.value[0].products;
});

const onSubmit = async () => {
	isUpdatingCart.value = true;

	if (selectedProducts.value.length > 0) {
		for (const id of selectedProducts.value) {
			await addProduct({
				id,
				quantity: 1
			});
		}
	}

	addedToCartObjectIDs(
		selectedProducts.value,
		selectedProducts.value.map(
			(productId) => products.value.find((product) => product.id === productId) as KippieProduct
		)
	);

	closeDrawer();

	isUpdatingCart.value = false;
};

watchEffect(() =>
	nextTick(() => {
		if (!isLoading.value && productAssociations.value.length === 0) {
			closeDrawer();
		}
	})
);
</script>

<template>
	<div class="flex flex-col gap-6">
		<span v-if="isLoading.value">Producten worden geladen...</span>

		<template v-if="!!productAssociations.value[0]">
			<KippieCrossSellProductSelector
				v-model="selectedProducts"
				v-slot="{ isSelected, onClick }"
				:class="{
					'pointer-events-none opacity-50': isUpdatingCart
				}"
			>
				<KippieCrossSellProduct
					v-for="product in products"
					:key="product.id"
					:checked="isSelected(product.id)"
					@click="onClick(product.id)"
					:name="getTranslatedProperty(product, 'name')"
					:price="formatPrice(product.calculatedPrice.totalPrice, true)"
				>
					<template #image>
						<img
							:src="getThumbnailUrl(product.cover.media)"
							width="100"
							quality="100"
							class="px-2 w-full h-full object-contain"
						/>
					</template>
				</KippieCrossSellProduct>
			</KippieCrossSellProductSelector>
		</template>

		<KippieButton
			:color="selectedProducts.length > 0 ? 'yellow' : 'white'"
			size="small"
			@click="onSubmit"
			:loading="isUpdatingCart"
		>
			{{
				selectedProducts.length > 0
					? `${selectedProducts.length} bijgerechten bestellen`
					: "Doorgaan zonder bijgerecht"
			}}
		</KippieButton>
	</div>
</template>
